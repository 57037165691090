import { HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ParamsObject, Patient, RestResponse } from '@mmx/shared'
import * as moment from 'moment'
import { map, Observable, ReplaySubject, shareReplay, switchMap } from 'rxjs'

import { AuthenticatedRestService } from './auth-rest.service'

export interface StructuredSearchFields {
  name: string
  eid?: string
  phone: string
  dob: moment.Moment
}

export enum SearchDocumentType {
  PATIENTS = 'patients',
  APPOINTMENTS = 'appts',
  INVOICES = 'invoices',
}

function createPatientObjects(object) {
  const { results, current, size, total_pages, total_results } = object.data.patients
  const data = results.map((p) => new Patient(p))

  return {
    data,
    page: {
      current: current - 1,
      size,
      totalPages: total_pages,
      totalResults: total_results,
    },
  }
}

@Injectable({
  providedIn: 'root',
})
export class SearchService extends AuthenticatedRestService {
  private route = '/clinicians/search'

  searchPatientsSubject = new ReplaySubject<ParamsObject>(1)

  private request = (params: HttpParams) => this.get<any>(this.route, params)

  searchResult$ = this.searchPatientsSubject.pipe(
    switchMap(this.searchPatients.bind(this)),
    map(createPatientObjects),
    shareReplay(1),
  )

  searchPatients(search: ParamsObject): Observable<RestResponse<any>> {
    return this.request(this.convertToParams(search))
  }
}
