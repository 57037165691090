import { Injectable } from '@angular/core'
import { ISection, ISectionPrintIntoTypes, toData } from '@mmx/shared'
import { map } from 'rxjs/operators'

import { AuthenticatedRestService } from './auth-rest.service'

export interface GetSectionsParams {
  printInto?: ISectionPrintIntoTypes
  includeHidden?: boolean
  includeDeleted?: boolean
}

@Injectable()
export class IntakeSectionsService extends AuthenticatedRestService {
  private readonly route = '/clinic-settings/sections'

  public getSections(params: GetSectionsParams) {
    return this.get<ISection[]>(this.route, this.convertToParams(params as Record<string, string | boolean>))
      .pipe(
        map(toData),
      )
  }
}
